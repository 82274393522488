import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";
import env from "react-dotenv";
import { getOS, OS, getBrowser, Browser } from "@twin-shared";

const {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
} = env;

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

// Check if the current browser supports Firebase Messaging
export const isMessagingSupported = async () => {
  try {
    const supported = await isSupported();
    const isIOSSafari = getOS() === OS.IOS && getBrowser() === Browser.Safari;
    return supported && !isIOSSafari;
  } catch {
    return false;
  }
};

// Initialize messaging only if supported
export const initializeMessaging = async () => {
  if (await isMessagingSupported()) {
    return getMessaging(firebaseApp);
  }
  return null;
};

export const registerServiceWorker = async () => {
  if (!(await isMessagingSupported())) {
    return;
  }

  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("Service Worker registered with scope:", registration.scope);
    } catch (error) {
      console.error("Service Worker registration failed:", error);
    }
  }
};
