import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getOS, OS, getBrowser, Browser } from "@twin-shared";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../app/store";
import { registerServiceWorker, initializeMessaging, isMessagingSupported } from "../utility/firebase-config";
import { getToken, onMessage } from "firebase/messaging";
import env from "react-dotenv";
import { getBrowserId } from "../utility";
import { useSocketContext } from "./SocketProvider";
import {
  PUSH_NOTIFICATION_TOKEN_KEY,
  PUSH_NOTIFICATION_DEVICE_ID,
  PUSH_NOTIFICATION_TOKEN_ID,
} from "@twin-shared/lib/constants";
import { addClickId, addNotification, removeNotification, updateClickIds } from "../features/notification";
import { clearNotifications, getNotifications } from "../utility/indexedDB";

const { FIREBASE_VAPID_KEY } = env;

interface AppContextProps {
  readonly isChatBoxFocused: boolean;
  authToken: string | null;
  hasPermissions: boolean;
  hasNotificationPermissions: boolean;
  os: OS | null;
  browser: Browser | null;
  requestPermissions: () => void;
  requestNotificationPermission: () => void;
  setIsChatBoxFocused: (val: boolean) => void;
}

export const AppContext = createContext<AppContextProps>({
  isChatBoxFocused: false,
  authToken: null,
  hasPermissions: false,
  hasNotificationPermissions: false,
  os: null,
  browser: null,
  requestPermissions: () => null,
  requestNotificationPermission: () => null,
  setIsChatBoxFocused: () => null,
});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const { setNotification, isOnline } = useSocketContext();
  const authToken = useSelector((state: RootState) => state.app.authToken);
  const [isChatBoxFocused, setIsChatBoxFocused] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(false);
  const [hasNotificationPermissions, setHasNotificationPermissions] = useState(
    typeof Notification !== 'undefined' && Notification?.permission === "granted"
  );
  const notificationList = useSelector((state: RootState) => state.notification.list);
  const notificationClickIds = useSelector((state: RootState) => state.notification.clickIds);
  const [os, setOs] = useState<OS | null>(null);
  const [browser, setBrowser] = useState<Browser | null>(null);
  const [fcmTokenState, setFcmTokenState] = useState<String>(null);
  const dispatch: AppDispatch = useAppDispatch();
  const [showNotificationPermissions, setShowNotificationPermissions] =
    useState(false);
  const [messagingSupported, setMessagingSupported] = useState(false);
  const [messagingInstance, setMessagingInstance] = useState(null);

  useEffect(() => {
    const initializeApp = async () => {
      setOs(getOS());
      setBrowser(getBrowser());

      // Check if messaging is supported
      const isSupported = await isMessagingSupported();
      setMessagingSupported(isSupported);

      if (isSupported) {
        // Initialize messaging
        const messaging = await initializeMessaging();
        setMessagingInstance(messaging);

        // Register service worker
        await registerServiceWorker();

        if (messaging) {
          // Set up message handler
          onMessage(messaging, (payload) => {
            console.log("Message received: ", payload.messageId);
            dispatch(
              addNotification({ ...payload, id: payload.data.id, markAsRead: false, expand: false })
            );
          });
        }
      }

      // Check microphone permissions
      if (navigator.permissions) {
        try {
          const permissionStatus = await navigator.permissions.query({ 
            name: "microphone" as PermissionName 
          });
          
          if (getBrowser() === Browser.Safari) {
            setHasPermissions(true);
          } else {
            setHasPermissions(permissionStatus.state === "granted");
            permissionStatus.onchange = function () {
              setHasPermissions(this.state === "granted");
            };
          }
        } catch (error) {
          console.error('Error checking microphone permissions:', error);
        }
      }
    };
    
    initializeApp();
  }, [dispatch]);

  useEffect(() => {
    let timeout: any = -1;
    const handleVisibilityChange = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (!document.hidden) {
          getNotifications()
            .then(
              (notifications: any) =>
                new Promise((res) => {
                  notifications.forEach((payload: any) => {
                    const notFound = notificationList.indexOf(item => item.data.id !== payload.data.id) === -1
                    if (notFound) {
                      dispatch(
                        addNotification({ ...payload, id: payload.data.id, markAsRead: false, expand: false })
                      );
                    }
                  });
                  res(notifications);
                })
            )
            .then(() => clearNotifications())
            .then(() => console.log("Data imported successfuly!"));
        }
      }, 1000);
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    let timeout: any = -1;  
    if (authToken && messagingSupported) {
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        // If we already have a token, send it to the server
        const existingToken = localStorage.getItem(PUSH_NOTIFICATION_TOKEN_KEY);
        if (existingToken) {
          setNotification({
            fcmToken: existingToken,
            deviceId: localStorage.getItem(PUSH_NOTIFICATION_DEVICE_ID),
          });
        } 
        // If we have permission but no token, try to get a new token
        else if (Notification?.permission === "granted" && messagingInstance) {
          try {
            const token = await getToken(messagingInstance, { vapidKey: FIREBASE_VAPID_KEY });
            if (token) {
              const browserId = getBrowserId();
              try {
                localStorage.setItem(PUSH_NOTIFICATION_TOKEN_KEY, token);
                localStorage.setItem(PUSH_NOTIFICATION_DEVICE_ID, browserId);
              } catch (storageError) {
                console.warn('Could not store FCM token in localStorage:', storageError);
                // Continue anyway as we can still use the token for this session
              } 
              setFcmTokenState(token);
              setNotification({
                fcmToken: token,
                deviceId: browserId,
              });

            }
          } catch (error) {
            console.error('Error getting FCM token:', error);
          }
        }
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [isOnline, authToken, messagingInstance,messagingSupported]);

  useEffect(() => {
    const ids = [];
    if (notificationClickIds && notificationClickIds.length > 0) {
      notificationClickIds.map(id => {
        if (notificationList.findIndex(a => a.id === id) !== -1) {
          dispatch(removeNotification(id))
          ids.push(id)
        }
      })

      if (ids.length > 0) {
        dispatch(updateClickIds(ids))
      }
    }
  }, [notificationClickIds, notificationList])

  const requestPermissions = async () => setHasPermissions(true);

  const requestNotificationPermission = async () => {
    if (!messagingSupported) {
      console.log('Push notifications are not supported in this browser');
      return;
    }

    try {
      const permission = await Notification.requestPermission();
      setHasNotificationPermissions(permission === "granted");
      setShowNotificationPermissions(false);
      
      if (permission === "granted" && messagingInstance) {
        try {
          const token = await getToken(messagingInstance, { vapidKey: FIREBASE_VAPID_KEY });
          if (token) {
            const browserId = getBrowserId();
            try {
              localStorage.setItem(PUSH_NOTIFICATION_TOKEN_KEY, token);
              localStorage.setItem(PUSH_NOTIFICATION_DEVICE_ID, browserId);
            } catch (storageError) {
              console.warn('Could not store FCM token in localStorage:', storageError);
              // Continue anyway as we can still use the token for this session
            }
            setFcmTokenState(token); 
            // If user is already logged in, send the token to server
            if (authToken) {
              setNotification({
                fcmToken: token,
                deviceId: browserId,
              });
            }
          }
        } catch (error) {
          console.error('Error getting FCM token:', error);
        }
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };

  useEffect(() => {
    if (
      hasPermissions &&
      !hasNotificationPermissions &&
      !localStorage.getItem(PUSH_NOTIFICATION_TOKEN_KEY)
    ) {
      setTimeout(() => setShowNotificationPermissions(true), 1000);
    }

    if (
      hasPermissions &&
      hasNotificationPermissions &&
      !localStorage.getItem(PUSH_NOTIFICATION_TOKEN_KEY)
    ) {
      requestNotificationPermission();
    }
  }, [hasNotificationPermissions, hasPermissions, authToken]);

  return (
    <AppContext.Provider
      value={{
        authToken,
        hasPermissions,
        hasNotificationPermissions,
        requestPermissions,
        requestNotificationPermission,
        isChatBoxFocused,
        os,
        browser,
        setIsChatBoxFocused,
      }}
    >
      {showNotificationPermissions &&
        localStorage.getItem("disable_alert") !== "true" &&
        Notification?.permission === "default" && (
          <div className="flex sm:opacity-90 md:opacity-80 z-50 flex-row  text-right absolute md:right-2 md:top-2 border p-5 bg-white rounded-lg shadow-lg max-w-[23rem]">
            <div className="flex justify-center items-center pr-5">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                className="text-blue-500 w-6 h-6 animate-bounce"
                aria-hidden="true"
                data-pc-el-id="FaBell-14-14"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"></path>
              </svg>
            </div>
            <div className="text-left">
              <h3 className="font-semibold text-lg">Enable Notifications</h3>
              <p className="mb-3">
                Stay updated with the latest updates by enabling notifications.
              </p>
              <button
                className="my-1 mr-2 px-4 py-2 font-medium text-center inline-flex items-center text-white rounded-md border border-blue-600 bg-blue-600 hover:bg-blue-700 transition focus:ring-4 focus:outline-none focus:ring-gray-300"
                onClick={() => {
                  requestNotificationPermission();
                  setShowNotificationPermissions(false);
                }}
              >
                Enable
              </button>

              <button
                className="my-1 mr-2 px-4 py-2 font-medium text-center inline-flex items-center text-white rounded-md border border-slate-600 bg-slate-600 hover:bg-gray-700 transition focus:ring-4 focus:outline-none focus:ring-gray-300"
                onClick={() => {
                  setShowNotificationPermissions(false);
                  localStorage.setItem("disable_alert", "true");
                }}
              >
                Not Now
              </button>
            </div>
            <div className="flex justify-center items-start">
              <button
                className="text-gray-400 hover:text-gray-500"
                aria-label="Close"
                data-pc-el-id="button-23-25"
                onClick={() => setShowNotificationPermissions(false)}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 352 512"
                  className="w-5 h-5"
                  data-pc-el-id="FaTimes-24-24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                </svg>
              </button>
            </div>
          </div>
        )}
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("AppContext must be used inside the AppProvider");
  }

  return context;
};
