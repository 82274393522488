import React from "react";
import { Notification } from "../../components";
import { useSocketContext } from "../../providers";

export const NotificationList = () => {
  const { sendNotificationCallback } = useSocketContext();

  const actionClickHandler = (data) => {
    // TODO: ->
    // API Call https://dev.scheduler.twin4ever.com to schedule a task
    
    if (data.action.type === 'open_in_chat') {
      sendNotificationCallback(data.action.notification_callback)
    }
  }
  return (
    <Notification
      iconname="BellFilled"
      onActionClick={(data) =>
        actionClickHandler(data)
      }
    />
  );
};
