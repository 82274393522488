import React, { useContext, useEffect, useMemo } from "react";
import { TimePicker } from "antd";
import { formatDateTime } from "../../utility";
import { getTimezoneOffset, removeTimezoneOffset } from "@twin-shared";
import dayjs from "dayjs";
import { ThemeContext } from "../../providers";

interface TimeInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const TimeInput = (props: TimeInputProps) => {
  const { theme } = useContext(ThemeContext);
  const { data, component, onChange, args, ...rest } = props;
  let timeFormat = "" + component.format;
  if (component.use12hours) {
    timeFormat = component.format.replace("HH", "hh");
    if (!component.format?.toLowerCase().includes("a")) {
      timeFormat = timeFormat.toLowerCase() + " A";
    }
  }
  useEffect(() => {
    if (data[component.id]) {
      let value = removeTimezoneOffset(data[component.id]);
      value = value + " " + getTimezoneOffset();
      onChange(component, value);
    }
  }, []);

  const convertTimeValue = (timeValue: string | null) => {
    if (!timeValue) return null;
    try {
      if (component.use12hours) {
        if (
          timeValue.toLowerCase().includes("a") ||
          timeValue.toLowerCase().includes("p")
        ) {
          return dayjs(timeValue, ["hh:mm:ss A", "hh:mm A"]).format(
            "hh:mm:ss A"
          );
        }
        return dayjs(timeValue, [
          "HH:mm:ss",
          "HH:mm",
          "hh:mm:ss A",
          "hh:mm A",
        ]).format("hh:mm:ss A");
      } else {
        // Convert 12-hour format with AM/PM to 24-hour format
        return dayjs(timeValue, [
          "hh:mm:ss A",
          "hh:mm A",
          "HH:mm:ss",
          "HH:mm",
        ]).format("HH:mm:ss");
      }
    } catch (error) {
      return null;
    }
  };

  const handleOnChange = (value: any) => {
    if (!value) {
      // Handle clear/close action
      onChange(component, null);
      return;
    }
    value = formatDateTime(value, component) + " " + getTimezoneOffset();
    onChange(component, value);
  };

  const manageValue = () => {
    let dataValue = data[component.id];
    if (!dataValue) {
      return null;
    }
    try {
      dataValue = removeTimezoneOffset(dataValue);
      if (!dataValue) {
        return null;
      }
      const convertedTime = convertTimeValue(dataValue);
      if (!convertedTime) {
        return null;
      }
      return dayjs(convertedTime, timeFormat);
    } catch (error) {
      console.error("Error parsing time value:", error);
      return null;
    }
  };
  
  const isMobile = useMemo(() => {
      return window.innerWidth < 640;
    }, [window.innerWidth]);

  return (
    <TimePicker
      placeholder={component.placeholder}
      value={manageValue()}
      onChange={(time) => handleOnChange(time)}
      format={timeFormat}
      {...args}
      {...rest}
      getPopupContainer={(triggerNode) => isMobile ? triggerNode.parentElement : document.body} 
      onTouchStart={(e) => e.stopPropagation()}
      use12Hours={component.use12hours}
      className="h-9"
      popupClassName={theme.isDark ? "dark-theme" : ""}
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        border: 0,
        zIndex: 9999
      }}
      zIndexPopup={9999999}
    />
  );
};
